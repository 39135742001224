<template>
    <div>
        <Html>
            <Head>
                <Title>WeTix</Title>
            </Head>
        </Html>
        <Body></Body>
        <slot />
    </div>
</template>

<script>

export default {

    setup() {
        return {

        }
    },
}
</script>